import Header from './pages/header';
import Home from './pages/index'
import About from './pages/about'
import Services from './pages/services'
import Features from './pages/features'
import Contact from './pages/contact'
import Footer from './pages/footer';
import Alert from './pages/alert'
import Policy from './pages/privacy-policy';
import Pricing from './pages/our-pricing';
import Error from './pages/error'
import { Routes, Route } from 'react-router-dom';
import Loader from './pages/loader';
import { useState, useEffect } from 'react';

function App() {
  const [isLoaded, setIsLoaded] = useState(true)
  useEffect(()=>{
    setTimeout(()=>{
      setIsLoaded(false)
    },2000)
  },[])
  return (
    <>
    {/* {
      isLoaded &&
      <Loader/>
    } */}
    <Alert/>
      <Header/>
      <Routes>
          <Route default path="/" element={<Home/>}></Route>
          <Route exact path="/about-us" element={<About/>}></Route>
          <Route exact path="/services" element={<Services/>}></Route>
          <Route exact path="/our-features" element={<Features/>}></Route>
          <Route exact path="/our-pricing" element={<Pricing/>}></Route>
          <Route exact path="/contact" element={<Contact/>}></Route>
          <Route exact path="/privacy-policy" element={<Policy/>}></Route>
          <Route path="*" element={<Error/>}></Route>
      </Routes>
      <Footer/>
    </>
  );
}

export default App;
