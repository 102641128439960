import { NavLink } from "react-router-dom";
import { BsArrowRight } from 'react-icons/bs'
import Hero from '../assets/images/hero.png'
import customize from '../assets/icons/customize.svg'
import responsive from '../assets/icons/responsive.svg'
import support from '../assets/icons/friendly-support.svg'
import Card from "../components/card";
import FAQ from "../components/faq";
import clientFirst from '../assets/images/client-first.jpg'
import prioritySupport from '../assets/images/support.png'
import guarntee from '../assets/images/guarntee.png'
import maintenance from '../assets/images/maintenance.png'
const feature = () => {
    return (
        <>
            {/* Hero section start here */}
            <section>
                <div className="hero">
                    <div className="container">
                        <div className="hero-wrapper">
                            <div className="hero-left">
                                <div className="hero-content-wrapper">
                                    <h1>Building stellar websites for early startups</h1>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.</p>
                                    <div className="hero-buttons">
                                        <NavLink to="#"><button className="primary-btn">View Our Work</button></NavLink>
                                    </div>
                                </div>
                            </div>
                            <div className="hero-right">
                                <img src={Hero} alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* Hero section ends here */}

            {/* Benefits section start here */}

            <section>
                <div className="benefits">
                    <div className="container">
                        <h2>The benefits of working with us</h2>
                        <div className="benefits-wrapper">
                            <Card
                                imgURL={customize}
                                title="Customize with ease"
                                desc="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua ut enim."
                            />
                            <Card
                                imgURL={responsive}
                                title="Perfectly Responsive"
                                desc="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua ut enim."
                            />
                            <Card
                                imgURL={support}
                                title="Friendly Support"
                                desc="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua ut enim."
                            />
                        </div>
                    </div>
                </div>
            </section>

            {/* Benefits section ends here */}

            {/* Client benefits section start here*/}

            <section>
                <div className="client-benefits">
                    <div className="container">
                        <div className="client-benefits-wrapper">
                            <div className="client-benefits-left">
                                <span>Use Client-first</span>
                                <h3>Top agencies and freelancers around the world use
                                    Client-first </h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation.</p>
                            </div>
                            <div className="client-benefits-right">
                                <img src={clientFirst} alt="" />
                            </div>
                        </div>
                    </div>

                    <div className="client-benefits-wrapper-background">
                        <div className="client-benefits-right">
                            <img src={maintenance} alt="" />
                        </div>
                        <div className="client-benefits-left">
                            <span>Free Revision Rounds</span>
                            <h3>Get free Revisions and one week of free maintenance</h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.</p>
                        </div>
                    </div>
                    <div className="container">
                        <div className="client-benefits-wrapper">
                            <div className="client-benefits-left">
                                <span>24/7 Support</span>
                                <h3>Working with us, you will be getting 24/7 priority support</h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.</p>
                            </div>
                            <div className="client-benefits-right">
                                <img src={prioritySupport} alt="" />
                            </div>
                        </div>
                    </div>
                    <div className="client-benefits-wrapper-background client-row-jasmine-background">
                        <div className="client-benefits-right">
                            <img src={guarntee} alt="" />
                        </div>
                        <div className="client-benefits-left">
                            <span>Quick Delivery</span>
                            <h3>Guranteed 1 week delivery for standard five pager website</h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.</p>
                        </div>
                    </div>
                </div>
            </section>

            {/* Client benefits section ends here*/}

            {/* FAQ section start here */}

            <FAQ />
            {/* FAQ section Ends here */}
        </>
    );
}

export default feature;