const Alert = () =>{
    return (
        <> 
            <div className="alert" style={{background:'red'}}>
                <h4 style={{textAlign:"center",marginBottom:0,color:"white",padding:10, fontSize:20}}>We are Under Maintainence</h4>
            </div>
        </>
    );
}

export default Alert