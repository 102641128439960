const Contact = () => {
    return (
        <>
            <section>
                <div className="contact">
                    <div className="container">
                        <div className="contact-title">
                            <h2>Contact Us</h2>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.</p>
                        </div>
                        <div className="contact-form">
                            <form action="">
                                <div className="contact-wrapper">
                                    <div className="name">
                                        <label htmlFor="name">Name*</label>
                                        <input type="text" id="name" placeholder="Enter your name" required />
                                    </div>
                                    <div className="email">
                                        <label htmlFor="email">Email*</label>
                                        <input type="email" name="" id="email" placeholder="Enter your email" required />
                                    </div>
                                </div>
                                <div className="contact-wrapper subject-row">
                                    <div className="subject">
                                        <label htmlFor="subject">Subject*</label>
                                        <input type="text" placeholder="Provide context" required />
                                    </div>
                                    <div className="service">
                                        <label htmlFor="services">Services</label>
                                        <input type="text" name="" id="" placeholder="Enter your service" required />
                                    </div>
                                </div>
                                <div className="message">
                                    <label htmlFor="msg">Message</label>
                                    <textarea name="" id="" cols="10" rows="10">Write your question here</textarea>
                                </div>
                                <button type="submit" className="primary-btn">Send Message</button>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Contact;