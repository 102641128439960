import React from "react";
import { NavLink } from "react-router-dom";
import { useState } from "react";
import logo from '../assets/images/logo.png'
const Header = () => {

    const [toggle, setToogle] = useState(false);

    return (
        <>
            <header>
                <div className="header">
                    <div className="container">
                        <div className="header-wrapper flex space-between align-center">
                            <div className="header-left">
                                <NavLink to="/"><img src={logo} alt="" /></NavLink>
                            </div>
                            <div className="header-right">
                                <div className="humberger">
                                    {toggle ?
                                        <div className="close" onClick={() => { setToogle(false) }}>
                                            <span className="line"></span>
                                            <span className="line"></span>
                                        </div>
                                        : <div className="open" onClick={() => { setToogle(!false) }}>
                                            <span className="line"></span>
                                            <span className="line"></span>
                                            <span className="line"></span>
                                        </div>
                                    }
                                </div>
                                {
                                        <ul className="flex space-end" style={ toggle ? {transform:'translateY(6.5%)',transition: '.3s linear'} : {transition: '.3s linear'} }>
                                            <li><NavLink to="/">Home</NavLink></li>
                                            <li><NavLink to="/about-us">About us</NavLink></li>
                                            <li><NavLink to="/our-services">Our Services</NavLink></li>
                                            <li><NavLink to="/our-features">Features</NavLink></li>
                                            <li><NavLink to="/our-pricing">Pricing</NavLink></li>
                                            <li><NavLink to="/contact"> <button className="global-btn">Contact us</button> </NavLink></li>
                                        </ul>
                                }

                            </div>
                        </div>  
                    </div>
                </div>
            </header>
        </>
    )
}

export default Header;