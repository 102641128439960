import { NavLink } from 'react-router-dom';
import React, { useRef, useState } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import Hero from '../assets/images/hero.png';
import { BsArrowRight } from "react-icons/bs";
import HowIcon1 from '../assets/icons/how-we-work-icon-1.svg'
import HowIcon2 from '../assets/icons/how-we-work-icon-2.svg'
import HowIcon3 from '../assets/icons/how-we-work-icon-3.svg'
import HowIcon4 from '../assets/icons/how-we-work-icon-4.svg'
import Project1 from '../assets/images/project-1.jpg'
import Project2 from '../assets/images/project-2.jpg'
import Project3 from '../assets/images/project-3.jpg'
import Client from '../assets/icons/client.svg'
import Free from '../assets/icons/free.svg'
import Customization from '../assets/icons/customization.svg'
import Support from '../assets/icons/support.svg'
import Delivery from '../assets/icons/delivery.svg'
import Approach from '../assets/icons/approach.svg'
import { AiOutlinePlus } from "react-icons/ai"
import Enquiry from '../assets/images/enquiry.png'
import FAQ from '../components/faq';
import client from '../assets/images/client.jpg'
import { IoIosArrowForward, IoIosArrowBack } from 'react-icons/io'


const Home = () => {

    return (
        <>
            {/* Hero section start here */}
            <section>
                <div className="hero">
                    <div className="container">
                        <div className="hero-wrapper">
                            <div className="hero-left">
                                <div className="hero-content-wrapper">
                                    <h1>Building stellar websites for early startups</h1>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.</p>
                                    <div className="hero-buttons">
                                        <NavLink to="#"><button className="primary-btn">View Our Work</button></NavLink>
                                        <NavLink to="#"><button className="pricing-btn global-btn">View Pricing <BsArrowRight /> </button></NavLink>
                                    </div>
                                </div>
                            </div>
                            <div className="hero-right">
                                <img src={Hero} alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* Hero section ends here */}

            {/* How we work section start here */}
            <section>
                <div className="how-we-work">
                    <div className="container">
                        <div className="how-we-work-wrapper">
                            <div className="how-we-work-left">
                                <h2>How we work</h2>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.</p>
                                <NavLink to="#">Get in touch with us <BsArrowRight /></NavLink>

                            <div className="circle-animate">
                                <div className="first-circle-animation"></div>
                                <div className="second-circle-animation"></div>
                            </div>
                                
                            </div>
                            <div className="how-we-work-right">
                                <div className="how-we-work-icon-wrapper">
                                    <div className="how-we-work-icon-left">
                                        <div className='card'>
                                            <img src={HowIcon1} alt="" />
                                            <h4>Strategy</h4>
                                            <p>Euismod faucibus turpis eu gravida mi. Pellentesque et velit aliquam .</p>
                                        </div>
                                        <div className='card'>
                                            <img src={HowIcon3} alt="" />
                                            <h4>Wireframing</h4>
                                            <p>Euismod faucibus turpis eu gravida mi. Pellentesque et velit aliquam .</p>
                                        </div>
                                    </div>
                                    <div className="how-we-work-icon-left">
                                        <div className='card'>
                                            <img src={HowIcon2} alt="" />
                                            <h4>Design</h4>
                                            <p>Euismod faucibus turpis eu gravida mi. Pellentesque et velit aliquam .</p>
                                        </div>
                                        <div className='card'>
                                            <img src={HowIcon4} alt="" />
                                            <h4>Development</h4>
                                            <p>Euismod faucibus turpis eu gravida mi. Pellentesque et velit aliquam .</p>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* How we work section start here */}

            {/* View our project section start here */}

            <section>
                <div className="our-projects">
                    <div className="container">
                        <div className="out-project-title">
                            <h2>View Our Project</h2>
                            <NavLink to="#"><p>View More<BsArrowRight /></p></NavLink>
                        </div>
                        <div className="our-project-wrapper">
                            <div className="our-project-left">
                                <div className='card'>
                                    <img src={Project1} alt="" />
                                    <div className="project-content">
                                        <h5>Workhub office Webflow Webflow Design</h5>
                                        <p>Euismod faucibus turpis eu gravida mi. Pellentesque et velit aliquam </p>
                                        <NavLink to="#">View portfolio<BsArrowRight /></NavLink>
                                    </div>
                                </div>
                            </div>
                            <div className="our-project-right">
                                <div className='card'>
                                    <img src={Project2} alt="" />
                                    <div className="project-content">
                                        <h5>Unisaas website design</h5>
                                        <NavLink to="#">View portfolio<BsArrowRight /></NavLink>
                                    </div>
                                </div>
                                <div className='card'>
                                    <img src={Project3} alt="" />

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* View our project section ends here */}

            {/* features section start here */}

            <section>
                <div className="features">
                    <div className="container">
                        <div className="features-title">
                            <span>Feautues</span>
                            <h2>Design that solves problems, one product at a time</h2>
                        </div>
                        <div className="row">
                            <div className="col">
                                <div className="card">
                                    <img src={Client} alt="" />
                                    <h5>Uses Client First</h5>
                                    <p>Euismod faucibus turpis eu gravida mi. Pellentesque et velit aliquam sed faucib turpis eu gravida mi. Pellentesque et velit aliquam sed mi. </p>
                                </div>
                            </div>
                            <div className="col">
                                <div className="card">
                                    <img src={Free} alt="" />
                                    <h5>Two Free Revision Round</h5>
                                    <p>Euismod faucibus turpis eu gravida mi. Pellentesque et velit aliquam sed faucib turpis eu gravida mi. Pellentesque et velit aliquam sed mi. </p>
                                </div>
                            </div>
                            <div className="col">
                                <div className="card">
                                    <img src={Customization} alt="" />
                                    <h5>Template Customization</h5>
                                    <p>Euismod faucibus turpis eu gravida mi. Pellentesque et velit aliquam sed faucib turpis eu gravida mi. Pellentesque et velit aliquam sed mi. </p>
                                </div>
                            </div>
                            <div className="col">
                                <div className="card">
                                    <img src={Support} alt="" />
                                    <h5>24/7 Support</h5>
                                    <p>Euismod faucibus turpis eu gravida mi. Pellentesque et velit aliquam sed faucib turpis eu gravida mi. Pellentesque et velit aliquam sed mi. </p>
                                </div>
                            </div>
                            <div className="col">
                                <div className="card">
                                    <img src={Delivery} alt="" />
                                    <h5>Quick Delivery</h5>
                                    <p>Euismod faucibus turpis eu gravida mi. Pellentesque et velit aliquam sed faucib turpis eu gravida mi. Pellentesque et velit aliquam sed mi. </p>
                                </div>
                            </div>
                            <div className="col">
                                <div className="card">
                                    <img src={Approach} alt="" />
                                    <h5>Hands-on approach</h5>
                                    <p>Euismod faucibus turpis eu gravida mi. Pellentesque et velit aliquam sed faucib turpis eu gravida mi. Pellentesque et velit aliquam sed mi. </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* features section end here */}

            {/* testimonials section start here */}

            <section>
                <div className="testimonials">
                    <div className="container">
                        <div className="testimonial-wrapper">
                            <div className="testimonial-left">
                                <h3>What our client says about us</h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit sed.</p>
                            </div>
                            <div className="testimonial-right">
                                <Swiper navigation={true}
                                modules={[Navigation]}
                                className="mySwiper"
                                >
                                    <SwiperSlide>
                                        <div className="testimonial-slider-wrapper">
                                            <h4>"The best agency we’ve worked with so far. They understand our product and are able to add new features with a great focus."</h4>
                                            <div className="testimonial-slider-inner-wrapper">
                                                <div className="testimonial-client">
                                                    <div className='client-img'>
                                                        <img src={client} alt="" />
                                                    </div>
                                                    <div className='client-name'>
                                                        <p>Jenny Wilson</p>
                                                        <span>Vice President</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="swiper-navigation">
                                            <div class="swiper-button-next"><IoIosArrowForward /></div>
                                            <div class="swiper-button-prev"><IoIosArrowBack /></div>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div className="testimonial-slider-wrapper">
                                            <h4>"The best agency we’ve worked with so far. They understand our product and are able to add new features with a great focus."</h4>
                                            <div className="testimonial-slider-inner-wrapper">
                                                <div className="testimonial-client">
                                                    <div className='client-img'>
                                                        <img src={client} alt="" />
                                                    </div>
                                                    <div className='client-name'>
                                                        <p>Jenny Wilson</p>
                                                        <span>Vice President</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="swiper-navigation">
                                            <div class="swiper-button-next"><IoIosArrowForward /></div>
                                            <div class="swiper-button-prev"><IoIosArrowBack /></div>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div className="testimonial-slider-wrapper">
                                            <h4>"The best agency we’ve worked with so far. They understand our product and are able to add new features with a great focus."</h4>
                                            <div className="testimonial-slider-inner-wrapper">
                                                <div className="testimonial-client">
                                                    <div className='client-img'>
                                                        <img src={client} alt="" />
                                                    </div>
                                                    <div className='client-name'>
                                                        <p>Jenny Wilson</p>
                                                        <span>Vice President</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="swiper-navigation">
                                            <div class="swiper-button-next"><IoIosArrowForward /></div>
                                            <div class="swiper-button-prev"><IoIosArrowBack /></div>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div className="testimonial-slider-wrapper">
                                            <h4>"The best agency we’ve worked with so far. They understand our product and are able to add new features with a great focus."</h4>
                                            <div className="testimonial-slider-inner-wrapper">
                                                <div className="testimonial-client">
                                                    <div className='client-img'>
                                                        <img src={client} alt="" />
                                                    </div>
                                                    <div className='client-name'>
                                                        <p>Jenny Wilson</p>
                                                        <span>Vice President</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="swiper-navigation">
                                            <div class="swiper-button-next"><IoIosArrowForward /></div>
                                            <div class="swiper-button-prev"><IoIosArrowBack /></div>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div className="testimonial-slider-wrapper">
                                            <h4>"The best agency we’ve worked with so far. They understand our product and are able to add new features with a great focus."</h4>
                                            <div className="testimonial-slider-inner-wrapper">
                                                <div className="testimonial-client">
                                                    <div className='client-img'>
                                                        <img src={client} alt="" />
                                                    </div>
                                                    <div className='client-name'>
                                                        <p>Jenny Wilson</p>
                                                        <span>Vice President</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="swiper-navigation">
                                            <div class="swiper-button-next"><IoIosArrowForward /></div>
                                            <div class="swiper-button-prev"><IoIosArrowBack /></div>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div className="testimonial-slider-wrapper">
                                            <h4>"The best agency we’ve worked with so far. They understand our product and are able to add new features with a great focus."</h4>
                                            <div className="testimonial-slider-inner-wrapper">
                                                <div className="testimonial-client">
                                                    <div className='client-img'>
                                                        <img src={client} alt="" />
                                                    </div>
                                                    <div className='client-name'>
                                                        <p>Jenny Wilson</p>
                                                        <span>Vice President</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="swiper-navigation">
                                            <div class="swiper-button-next"><IoIosArrowForward /></div>
                                            <div class="swiper-button-prev"><IoIosArrowBack /></div>
                                        </div>
                                    </SwiperSlide>
                                </Swiper>
                            </div>

                        </div>
                    </div>
                </div>
            </section>

            {/* testimonials section ends here */}

            {/* FAQ section start here */}


            <FAQ />

            {/* FAQ section ends here */}

            {/* Enquiry section start here */}

            <section>
                <div className="enquiry">
                    <div className="container">
                        <div className="enquiry-wrapper">
                            <div className="enquiry-left">
                                <img src={Enquiry} alt="" />
                                <div className="enquiry-text">
                                    <h1>Building stellar websites for early startups</h1>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua ut enim.</p>
                                </div>
                            </div>
                            <div className="enquiry-right">
                                <h4>Send Enquiry</h4>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.</p>
                                <form action="" method=''>
                                    <input type="text" placeholder='Your Name' id='name' required />
                                    <input type="email" placeholder='Your Email' id='email' required />
                                    <input type="number" placeholder='Your Mobile' id='mobile' required />
                                    <button type="submit" className='global-btn primary-btn  enquiry-btn'>Send an enquiry</button>
                                    <p>Get in touch with us <BsArrowRight /></p>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* Enquiry section ends here */}
        </>
    );
}

export default Home;