import { useState } from "react";
import { MdClose } from 'react-icons/md'
import { AiOutlinePlus } from 'react-icons/ai'
const Accordian = (props) => {
    const [click, setClick] = useState(false)
    const [id, setId] = useState(props.id)
    return (
        <>
            <div className="faq-question-wrapper" id={props.id}>
                <div className="faq-question-number" >
                    <h5>{props.number}</h5>
                </div>
                <div className="faq-question">
                    <h5>{props.question}</h5>
                    <div className='faq-answer'>
                        <p>{props.answer}</p>
                    </div>
                </div>
                <div className="faq-btn" onClick={(id) => { setClick(!false) }}>
                    {
                        click ?
                            <MdClose />

                            : <AiOutlinePlus />
                    }

                </div>
            </div>
        </>
    );
}
export default Accordian