import { useState } from 'react';
import { NavLink } from 'react-router-dom'
import { AiOutlinePlus } from "react-icons/ai"
import { MdClose } from 'react-icons/md'
import Accordian from './accordian';
const FAQ = () => {
    const [show, setShow] = useState(false);


    return (
        <>
            {/* FAQ section start here */}

            <div className="faq">
                <div className="container">
                    <div className="faq-wrapper">
                        <div className="faq-left">
                            <h3>Frequently asked questions</h3>
                            <NavLink to="/contact"><p>Contact us for more info</p></NavLink>
                        </div>
                        <div className="faq-right">
                            <Accordian
                             id="1"
                             number="06"
                             question="How much time does it take?"
                             answer="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt"
                             />
                            <div className="faq-question-wrapper">
                                <div className="faq-question-number" onClick={() => { setShow(!false) }}>
                                    <h5>01</h5>
                                </div>
                                <div className="faq-question">
                                    <h5>How much time does it take?</h5>
                                    <div className='faq-answer'>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                    </div>
                                </div>
                                <div className="faq-btn">
                                    {
                                        show ?
                                            <MdClose />
                                            : <AiOutlinePlus />
                                    }
                                </div>
                            </div>

                            <div className="faq-question-wrapper">
                                <div className="faq-question-number">
                                    <h5>02</h5>
                                </div>
                                <div className="faq-question">
                                    <h5>What is your class naming convention?</h5>
                                    {
                                        show &&
                                        <div className='faq-answer'>
                                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                        </div>
                                    }
                                </div>
                                <div className="faq-btn" onClick={() => { setShow(!false) }}>
                                    {
                                        show ?
                                            <MdClose />
                                            : <AiOutlinePlus />
                                    }
                                </div>
                            </div>
                            <div className="faq-question-wrapper">
                                <div className="faq-question-number" onClick={() => { setShow(!false) }}>
                                    <h5>03</h5>
                                </div>
                                <div className="faq-question">
                                    <h5>How do you communicate?</h5>
                                    {
                                        show &&
                                        <div className='faq-answer'>
                                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                        </div>
                                    }
                                </div>
                                <div className="faq-btn">
                                    {
                                        show ?
                                            <MdClose />
                                            : <AiOutlinePlus />
                                    }
                                </div>
                            </div>
                            <div className="faq-question-wrapper">
                                <div className="faq-question-number" onClick={() => { setShow(!false) }}>
                                    <h5>04</h5>
                                </div>
                                <div className="faq-question">
                                    <h5>I have a bigger project. Can you handle it?</h5>
                                    {
                                        show &&
                                        <div className='faq-answer'>
                                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                        </div>
                                    }
                                </div>
                                <div className="faq-btn">
                                    {
                                        show ?
                                            <MdClose />
                                            : <AiOutlinePlus />
                                    }
                                </div>
                            </div>
                            <div className="faq-question-wrapper">
                                <div className="faq-question-number" onClick={() => { setShow(!false) }}>
                                    <h5>05</h5>
                                </div>
                                <div className="faq-question">
                                    <h5>What is your class naming convention?</h5>
                                    {
                                        show &&
                                        <div className='faq-answer'>
                                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                        </div>
                                    }
                                </div>
                                <div className="faq-btn">
                                    {
                                        show ?
                                            <MdClose />
                                            : <AiOutlinePlus />
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* FAQ section ends here */}
        </>
    );
}
export default FAQ