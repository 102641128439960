import { NavLink } from "react-router-dom";
import notFound from  "../assets/images/not-found.avif"
const Error = () =>{
    return(
        <> 
        <div style={{display:'flex', alignItems:'center', justifyContent:'center'}}>
            <NavLink to='/'><img src={notFound} alt=""/></NavLink>
        </div>
        </>
    );
}

export default Error;