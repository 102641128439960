import True from '../assets/icons/true-pointer.svg'
import False from '../assets/icons/false-pointer.svg'
import FAQ from '../components/faq';

const Pricing = () =>{
    return(
        <>
            <section>
                <div className="our-pricing">
                    <div className="container">
                        <div className="our-pricing-title">
                            <h2>Our Pricing</h2>
                            <p>When you’re ready to go beyond prototyping in Figma, Webflow is ready to help you bring your designs to life — without coding them.</p>
                        </div>
                        <div className="our-pricing-wrapper">
                            <div className="our-pricing-row">
                                <div className="card">
                                    <h3>$299 <span>Per Design</span></h3>
                                    <h5>Landing Page</h5>
                                    <p>When you’re ready to go beyond prototyping in Figma, </p>
                                    <ul>
                                        <li> <img src={True} alt="" /> All limited links</li>
                                        <li> <img src={True} alt="" /> Own analytics platform</li>
                                        <li> <img src={True} alt="" /> Chat support</li>
                                        <li className="feature-not-included"> <img src={False} alt="" /> Optimize hashtags</li>
                                        <li className='feature-not-included'> <img src={False} alt="" /> Unlimited users</li>
                                    </ul>
                                    <button className='primary-btn'>Get Started</button>
                                </div>
                                <div className="card">
                                    <h3>$299 <span>Per Design</span></h3>
                                    <h5>Website Page</h5>
                                    <p>When you’re ready to go beyond prototyping in Figma, </p>
                                    <ul>
                                        <li> <img src={True} alt="" />All limited links</li>
                                        <li> <img src={True} alt="" /> Own analytics platform</li>
                                        <li> <img src={True} alt="" /> Chat support</li>
                                        <li> <img src={True} alt="" /> Optimize hashtags</li>
                                        <li> <img src={True} alt="" /> Unlimited users</li>
                                    </ul>
                                    <button className='primary-btn'>Get Started</button>

                                </div>
                                <div className="card">
                                    <h3>$499+<span>Per Design</span></h3>
                                    <h5>Landing Page</h5>
                                    <p>When you’re ready to go beyond prototyping in Figma, </p>
                                    <ul>
                                        <li> <img src={True} alt="" /> All limited links</li>
                                        <li> <img src={True} alt="" /> Own analytics platform</li>
                                        <li> <img src={True} alt="" /> Chat support</li>
                                        <li> <img src={True} alt="" /> Optimize hashtags</li>
                                        <li> <img src={True} alt="" /> Unlimited users</li>
                                        <li> <img src={True} alt="" /> Unlimited users</li>
                                    </ul>
                                    <button className='primary-btn'>Get Started</button>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <FAQ/>

            
        </>
    );
}
export default Pricing;