import support from '../assets/icons/friendly-support.svg'

const Card = (props) => {
    return (
        <>
            <div className="card">
                <img src={props.imgURL} alt="" />
                <h5>{props.title}</h5>
                <p>{props.desc}</p>
            </div>
        </>
    );
}

export default Card